import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './grtwork.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const BackgroundImage = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    {
      backgroundImage: file(absolutePath: { regex: "/gptw/" }) {
        childImageSharp {
            gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 130)
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  const greatplacework = getImage(backgroundImage.childImageSharp)
  return (
    backgroundImage && (
      <GatsbyImage
      image={greatplacework}
      
      />
    )
  )
}

export default BackgroundImage
